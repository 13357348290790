@import url(https://fonts.googleapis.com/css?family=Krub:500,600);
* {
    padding: 0;
    font-family: 'Krub', 'Roboto', 'Helvetica', sans-serif;
    --primary-background: #2e7235 ;
    --text-color: #111111;
    box-sizing: border-box;
}
body {
    margin: 0;
    font-family: 'Krub', sans-serif;
    background: var(--primary-background);
    color: var(--text-color);
}

p a {
    color: white;
    text-decoration: underline;
}
a {
    text-decoration: none;
}

